import AppSettings from "../../appsettings";
import React, { useState, useEffect, useRef } from "react";
import "../../../src/styles/scss/featureAvailabilityRows.scss"
import "../../../src/styles/scss/featureAvailabilityRowsPrintOnly.scss"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

/*
This component creates alternating shaded rows to display feature availability and optional comparison data.

required props:
 - data:
    - list of objects like:
        {
            featureName: string (required)
            mainData: string or jsx (required)
            comparisonData (optional)
        }
    - if comparisonData is present, availability will appear side by side (see H2H v2)
    - if comparisonData not present, availability will appear individually (see trim walk v2)

optional props:
 - availabilityIcons
        - if true, will parse mainData and comparisonData into availaiblitiy icons
        - keywords: standard, option, package, available, not available
 - stackedRows: displays comparison data stacked 
        - if true, will vertically stack feature name and availability data (see H2H v2 tablet/mobile)
*/

const FeatureAvailabilityRows = (props) => {

    const getAvailabilityIcon = (availability) => {
        switch (availability?.toLowerCase()) {
            case "standard":
                return ( 
                    <img 
                        className='standard-icon'
                        id='standard-icon-print'
                        src={AppSettings.AWSImgRepo.resources + `en/standard.png`} 
                        alt=''
                    />
                )
            case "option":
                return ( 
                    <img 
                        className='option-icon'
                        id='option-icon-print'
                        src={AppSettings.AWSImgRepo.resources + `en/option.png`} 
                        alt=''
                    />
                )
            case "package":
                return ( 
                    <img 
                        className='package-icon' 
                        src={AppSettings.AWSImgRepo.resources + `en/package.png`} 
                        alt=''
                    />
                )
            case "available":
                return <div className="h2h-availablity-indication" id="h2h-availability-indication-print">A</div>
            case "not available":
                return <div className="h2h-not-availablity-indication" id="h2h-not-availability-indication-print">—</div>

        }
    }

    return <div className="feature-data-rows">
        {props.data?.map((d, i) => 
            <div className={`feature-data-row ${i % 2 == 0 && 'shaded-row'} 
                ${props.availabilityIcons && "availability-icons"} 
                ${props.stacked && "stacked-rows"}
                ${!d.comparisonData && "single-feature"}`
                }>
                <div className="title-feature-name" id="title-feature-name-print">{d.featureName}</div>
                <div className="features-data">
                    <div className="main-feature-data" id="main-feature-data-print">{props.availabilityIcons ? getAvailabilityIcon(d.mainData) : d.mainData}</div>
                    {d.comparisonData && 
                        <>
                            <div className="vertical-divider" />
                            <div className="comparison-feature-data" id="comparison-feature-data-print">{props.availabilityIcons ? getAvailabilityIcon(d.comparisonData) : d.comparisonData}</div>
                        </>
                    }
                </div>
            </div>
        )}
    </div>
}

export default FeatureAvailabilityRows;
