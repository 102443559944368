import React, { forwardRef } from 'react';
import AppSettings from '../../appsettings';
import '../../styles/css/accordion.css'
import '../../styles/css/nested-accordion.css'
import { Accordion } from './Accordion';

export const NestedAccordion = forwardRef((props, collapseAccordionRef) => {

    /*
    required props
        data
            list of objects like
                {
                    outerHeader: string or jsx, required
                        row text before expanding
                        use applyMarkdown here and build jsx if multiple elements/styles
                    innerAccordionData: list of objects formatted as below
                        {
                            innerHeader: string or jsx, required
                                row test for inner accordion
                            body: jsx, required
                                row content below title after expanding for inner accordion
                        }
                }
        outerTitleStyling
                styling to apply to the outer accordion row title
        innerTitleStyling
                styling to apply to the inner accordion row title
    */

    const processAccordionData = () => {
        return props?.data?.map((outerItem, outerIndex) => (
            {
            title: outerItem?.outerHeader,
            key: `${outerIndex}-${outerItem?.outerHeader}`,
            scrollExpand: outerItem?.scrollExpand,
            body: outerItem?.innerAccordionData?.map((innerItem, innerIndex) => (
                <Accordion
                    titleStyling={props?.innerTitleStyling}
                    rows={[
                        {
                            title: innerItem?.innerHeader,
                            parentTitle: outerItem?.outerHeader,
                            key: `${innerIndex}-${innerItem?.innerHeader}`, 
                            body: innerItem?.body
                        }
                    ]}
                    shouldCollapseAllChildren={props?.shouldCollapseAllChildren}
                    shouldExpandAllChildren={props?.shouldExpandAllChildren}
                    className={props?.innerAccordionClassName ? props.innerAccordionClassName : 'inner-nested-accordion'}
                    expandIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt='' style={{transform: "scaleY(-1)"}}/>}
                    collapseIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt=''/>}
                    termAnalyticsClick={props?.innerAnalyticsClick}
                />
            )) 
        }));
    };

  
    return (
        <div className={'nested-accordion'}>
            <Accordion
                titleStyling={props?.outerTitleStyling}
                ref={collapseAccordionRef}
                rows={processAccordionData()}
                className={props?.outerAccordionClassName}
                termAnalyticsClick={props?.outerAnalyticsClick}
                expandCollapseAll={props?.expandCollapseAll}
                shouldCollapseAllChildren={props?.shouldCollapseAllChildren}
                shouldExpandAllChildren={props?.shouldExpandAllChildren}
                setShouldCollapseAllChildren={props?.setShouldCollapseAllChildren}
                setShouldExpandAllChildren={props?.setShouldExpandAllChildren}
            />
        </div>
    );
});
