import '../../../src/styles/css/vehicleDetails/accordion-expand-collapse-all-controls.css'
import '../../../src/styles/css/vehicleDetails/accordion-expand-collapse-all-controls-print-only.css'


const AccordionExpandCollapseAllControls = ({ expandAll = () => {}, collapseAll = () => {} }) => {
  return (
    <div
      className="accordion-expand-collapse-group"
      id="accordion-expand-collapse-group-print-only"
    >
      <div className="accordion-expand-collapse" onClick={() => expandAll()}>
        <span className="expand-collapse-icon">＋</span>
        <span className="expand-collapse-text">EXPAND ALL</span>
      </div>
      <div className="accordion-expand-collapse" onClick={() => collapseAll()}>
        <span className="expand-collapse-icon">－</span>
        <span className="expand-collapse-text">COLLAPSE ALL</span>
      </div>
    </div>
  );
};

export default AccordionExpandCollapseAllControls;
