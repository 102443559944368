import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { getVehicleYears } from "../../../services/vehicleService";
import {VehicleChoiceSections} from "./vehicleChoiceSections.js"
import { getDynamoName } from "../../utils/vehicleName";
import "../../../styles/css/resources-v2/head-to-head.css";
import { Accordion } from "../../../components/molecules/Accordion";
import AccordionLegend from "../../../components/molecules/accordionLegend";
import applyMarkdown from "../../utils/applyMarkdown";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import { getTrimOptionsByModelNameOnly } from "../../../services/trimOptionsService";
import '../../../styles/scss/resources-v2/trim-walk.scss';
import '../../../styles/scss/resources-v2/trim-walk-print-only.scss';
import "../../../styles/scss/resources-v2/disclaimers-footnotes-print-only.scss";
import TrimWalkAccordionBody from "./trimWalkAccordionBody";
import TrimWalkHeader from "./trimWalkHeader";
import Skeleton from "react-loading-skeleton";
import { accordionClick } from "../../vehicleDetails/vehicleDetails.analytics";
import { disclaimerClick } from "./trimWalk.analytics.js";
import { isDash } from "../../utils/helpers";
import { isDesktopView } from "../../utils/getScreenSize";
import { VehiclePageIsPrint } from "../../../components/contexts/vehiclePagePrintContext.js";

export const TrimWalk = (props) => {
  const [years, setYears] = useState(null);
  const [currentAccordionData, setCurrentAccordionData] = useState([]);
  const [vehicleOne, setVehicleOne] = useState(null);
  const [vehicleTwo, setVehicleTwo] = useState(null);
  const [vehicleThree, setVehicleThree] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(0);
  const [trimOptions, setTrimOptions] = useState(null);
  const [vehicleOneTrim, setVehicleOneTrim] = useState(null);
  const [showThirdColumn, setShowThirdColumn] = useState(true);
  const vehicleOneDisclaimerRef = useRef();
  const vehicleTwoDisclaimerRef = useRef();
  const vehicleThreeDisclaimerRef = useRef();
  const { isPrint } = useContext(VehiclePageIsPrint);
  const disclaimerRefs = [vehicleOneDisclaimerRef, vehicleTwoDisclaimerRef, vehicleThreeDisclaimerRef];
  const sortOrder = ['standard', 'option', 'package'];

  // VEHICLE FEATURE AND SPECTS DATA
  const [allFeaturesAndSpecsDataVehicleOne, setAllFeaturesAndSpecsDataVehicleOne] = useState(null);
  const [allFeaturesAndSpecsDataVehicleTwo, setAllFeaturesAndSpecsDataVehicleTwo] = useState(null);
  const [allFeaturesAndSpecsDataVehicleThree, setAllFeaturesAndSpecsDataVehicleThree] = useState(null);

  useEffect(() => {
    if (vehicleOne && vehicleTwo && vehicleThree && allFeaturesAndSpecsDataVehicleOne && allFeaturesAndSpecsDataVehicleTwo && allFeaturesAndSpecsDataVehicleThree) {
      const accordionData = [
        {
          title: 'Exterior',
          body: 
            <TrimWalkAccordionBody
              data={getFormattedData('Exterior')}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
            />,
          key: 0
        },
        {
          title: 'Interior',
          body:
            <TrimWalkAccordionBody
              data={getFormattedData('Interior')}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
            />,
          key: 1
        },
        {
          title: 'Safety/Convenience',
          body:
            <TrimWalkAccordionBody
              data={getFormattedData('Safety/Convenience')}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
            />,
          key: 2
        },
        {
          title: 'Packages',
          body:
            <TrimWalkAccordionBody
              data={getFormattedData('Packages')}
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
              packages
            />,
          key: 3
        }
      ]
      setCurrentAccordionData(accordionData);
    }
  }, [vehicleOne, vehicleTwo, vehicleThree, allFeaturesAndSpecsDataVehicleOne, allFeaturesAndSpecsDataVehicleTwo, allFeaturesAndSpecsDataVehicleThree, selectedVehicle])


  const loadYears = () => {
    return new Promise((resolve, reject) => {
      getVehicleYears(getDynamoName(props.model))
        .then((yearsData) => {
          setYears(yearsData);
          resolve(yearsData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const loadTrimOptions = () => {
    return new Promise((resolve, reject) => {
      getTrimOptionsByModelNameOnly(getDynamoName(props.model), "en")
        .then((data) => {
          setTrimOptions(data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(async() => {
    // Load trims and years when the component mounts
    if (props.year && props.model) {
      Promise.all([loadYears(), loadTrimOptions()])
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [props.model, props.year]);

  const getFormattedData = (type) => {
    if (type === 'Packages') {
      const vehicleOnePackages = getVehiclePackages(vehicleOne);
      const vehicleTwoPackages = getVehiclePackages(vehicleTwo);
      const vehicleThreePackages = getVehiclePackages(vehicleThree);
      const compiledPackageData = 
        [
          vehicleOnePackages,
          getAdditionalPackages(vehicleOnePackages, vehicleTwoPackages),
          ...(showThirdColumn ? [getAdditionalPackages(vehicleTwoPackages, vehicleThreePackages)] : [])
        ];
      return compiledPackageData.map((data, index) => formatPackagesForDisplay(data, disclaimerRefs[index]));
    } else {
      const vehicleOneData = getVehicleFeatures(type, allFeaturesAndSpecsDataVehicleOne, vehicleOne);
      const vehcileTwoData = getVehicleFeatures(type, allFeaturesAndSpecsDataVehicleTwo, vehicleTwo);
      const vehcileThreeData = getVehicleFeatures(type, allFeaturesAndSpecsDataVehicleThree, vehicleThree);
      const compiledFeatureData = 
        [
          vehicleOneData, 
          getAdditionalFeatures(vehicleOneData, vehcileTwoData),
          ...(showThirdColumn ? [getAdditionalFeatures(vehcileTwoData, vehcileThreeData)] : [])
        ];
      return compiledFeatureData.map((data, index) => formatFeaturesForDisplay(data, disclaimerRefs[index]));
    }
  };

  const formatFeaturesForDisplay = (data, disclamerRef) => {
    const finalData = data?.vehicleFeatures?.map((feature) => {
      const letter = feature.mainData;
      let word;
      switch (letter) {
        case 'S':
          word = 'standard';
          break;
        case 'O':
          word = 'option';
          break;
        case 'P':
          word  = 'package'
          break;
        default:
          word = letter;
      }
      
      return {featureName: applyMarkdown(feature.featureName, disclamerRef?.current), mainData: word};
    });

    finalData?.sort(function(a, b) {
      return sortOrder.indexOf(a.mainData) - sortOrder.indexOf(b.mainData);
    });

    return {...data, vehicleFeatures: finalData};
  };

  const formatPackagesForDisplay = (data, disclamerRef) => {
    const finalData = data?.vehiclePackages?.map((packageData) => {
      return {
        ...packageData,
        title: applyMarkdown(packageData.title, disclamerRef?.current),
        description: applyMarkdown(packageData.description, disclamerRef?.current), 
      };
    })

    return {...data, vehiclePackages: finalData};
  };

  const getVehicleFeatures = (category, allData, vehicle) => {
    const vehicleFeatures = allData
    ?.featuresAndSpecs[category]
    ?.map((category) => category.featureSpecs)
    ?.flat()
    ?.map((feature) => ({featureName: feature.Description, mainData:feature[vehicle?.modelCode]}))
    ?.filter((object) => !isDash(object.mainData));

    return {fullName: vehicle.fullName, vehicleFeatures: vehicleFeatures};
  };

  const getVehiclePackages = (vehicle) => {
    const packages = trimOptions?.filter((item) => item?.modelYear?.toString() === vehicle?.year)[0]
    ?.trimOptions
    ?.packages
    ?.filter((vehiclePackage) =>  vehiclePackage.availability.includes(parseInt(vehicle?.modelCode)));

    return {fullName: vehicle.fullName, vehiclePackages: packages};
  };

  const getAdditionalFeatures = (lowerVehicleFeatures, higherVehicleFeatures) => {
    const addedFeatures = higherVehicleFeatures?.vehicleFeatures?.filter((i) => 
      !lowerVehicleFeatures?.vehicleFeatures.find(j => i.featureName === j.featureName && i.mainData === j.mainData)
    );

    const noDuplicates = [...new Map(addedFeatures?.map(item => [item['featureName'], item])).values()];

    return {fullName: higherVehicleFeatures.fullName, vehicleFeatures: noDuplicates};
  };

  const getAdditionalPackages = (lowerVehiclePackages, higherVehiclePackages) => {
    const addedPackages = higherVehiclePackages?.vehiclePackages?.filter((i) => 
      !lowerVehiclePackages?.vehiclePackages.find(j => i.title === j.title && i.description === j.description)
    );
    return {fullName: higherVehiclePackages.fullName, vehiclePackages: addedPackages};
  };

  const updateSelectedVehicle = (vehicleNum, modelCode, year, msrp, name, fullName) => {
    switch (vehicleNum) {
      case 1:
        setVehicleOne({year: year, modelCode: modelCode, msrp: msrp, name: name, fullName: fullName});
        break;
      case 2:
        setVehicleTwo({year: year, modelCode: modelCode, msrp: msrp, name: name, fullName: fullName});
        break;
      case 3:
        setVehicleThree({year: year, modelCode: modelCode, msrp: msrp, name: name, fullName: fullName});
        break;
      default:
        break;
    }
  };

  const handleDisclaimerClick = (disclaimer) => {
    disclaimerClick({
      modelYear: props?.year,
      modelName: props?.model,
      label: disclaimer
    });
  }

  const getDisclaimerTitle = (vehicleNums) => {
    let nums = '';
    let length = vehicleNums.length;
    if (length < 3 && length > 0) {
        vehicleNums.forEach((num, index) => {
          if (index == 0 || length == 1) {
            nums = nums.concat(num)
          }
          if (index > 0) {

            nums = nums.concat(` and ${num}`)
          }
        })
        return (`Vehicle ${nums}`)
    }
    return ('')
  }

  const renderDisclaimersOnPrint = () => {
    const vehicleInfo = [
      {vehicleNum: 1, vehicleYear: vehicleOne?.year}, 
      {vehicleNum: 2, vehicleYear: vehicleTwo?.year},
      {vehicleNum: 3, vehicleYear: vehicleThree?.year},
    ];

    const groupedVehicles = Object.values(vehicleInfo.reduce((acc, vehicle) => {
      const { vehicleYear, vehicleNum } = vehicle;
      if (!acc[vehicleYear]) {
        acc[vehicleYear] = [];
      }
      acc[vehicleYear].push(vehicleNum);
      return acc;
    }, {}));

    const sortedGroupedVehicles = groupedVehicles.sort((a, b) => a[0] - b[0]);

      return (
        <div className="disclaimers-footnote" id="disclaimers-footnote-print-only">
            <div className='disclaimers-title'>Disclaimers</div>
            {sortedGroupedVehicles?.map(vehicleNums => {
              const disclaimerRefIndex = vehicleNums[0]-1;
              return <div key={vehicleNums}>  
                {getDisclaimerTitle(vehicleNums) == '' || <div className='disclaimers-footnote-vehicle-title'>{getDisclaimerTitle(vehicleNums)}</div>}
                <p>
                  {disclaimerRefs[disclaimerRefIndex]?.current && applyMarkdown(Object.values(disclaimerRefs[disclaimerRefIndex]?.current?.disclaimers).map((disclaimer, index) => 
                    `<b>${index+1}.</b>&nbsp;<text>${disclaimer}</text>`).join('&nbsp;')
                  )}
                </p>
              </div>
            })}
        </div> 
      );
  };

  return (
    <div className="trim-walk-component">
      <div className="vehicle-choice-sections">
        <VehicleChoiceSections
          year={props.year}
          model={props.model}
          years={years}
          updateSelectedModelCode={updateSelectedVehicle}
          updateFeatureAndSpecsVeh1={setAllFeaturesAndSpecsDataVehicleOne}
          updateFeatureAndSpecsVeh2={setAllFeaturesAndSpecsDataVehicleTwo}
          updateFeatureAndSpecsVeh3={setAllFeaturesAndSpecsDataVehicleThree}
          pageData={props.pageData}
          setShowThirdColumn={setShowThirdColumn}
          showThirdColumn={showThirdColumn}
        />
      </div>
      {(!isDesktopView(window.innerWidth) && currentAccordionData.length > 0 && !isPrint) &&
        <TrimWalkHeader
          vehicles={
            [
              vehicleOne,
              vehicleTwo,
              ...(showThirdColumn ? [vehicleThree] : [])
            ]
          }
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
        />
      }
      <AccordionLegend type={"sop"} />
      {currentAccordionData.length ? <Accordion
        expandCollapseAll
        rows={currentAccordionData}
        className="vehicle-page-accordion"
        autoCollapse={(!isDesktopView(window.innerWidth))}
        titleStyling={props?.outerTitleStyling}
        termAnalyticsClick={(term, isExpand) => {
          accordionClick({
            page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
            term: term,
              isExpand: isExpand,
              modelName: props?.model,
              modelYear: props?.year,
              isNested: false,
              trim: vehicleOneTrim
          });
      }}
      /> : <Skeleton height={40} count={4} /> }

      {renderDisclaimersOnPrint()}

      <DisclaimersComponent 
        template={`${vehicleOne?.year}_${props?.model.toLowerCase()}`}
        ref={vehicleOneDisclaimerRef} 
        analyticsOnSelection={handleDisclaimerClick}
      />
      <DisclaimersComponent 
        template={`${vehicleTwo?.year}_${props?.model.toLowerCase()}`}
        ref={vehicleTwoDisclaimerRef} 
        analyticsOnSelection={handleDisclaimerClick}
      />
      <DisclaimersComponent 
        template={`${vehicleThree?.year}_${props?.model.toLowerCase()}`}
        ref={vehicleThreeDisclaimerRef} 
        analyticsOnSelection={handleDisclaimerClick}
      />
    </div>
  );
};
