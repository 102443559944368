export class printableDocumentEvents {

    constructor(beforePrintCallback = this.setPageTitleToBlank, afterPrintCallback = this.setPageTitleToDefault) {
        this.beforePrintCallback = beforePrintCallback;
        this.afterPrintCallback = afterPrintCallback;
    }

    setPageTitleToBlank() {
        document.title = "\u200B";
    }

    setPageTitleToDefault() {
        document.title = "Toyota Engage"
    }

    subscribe() {
        window.addEventListener("beforeprint", this.beforePrintCallback);
        window.addEventListener("afterprint", this.afterPrintCallback)
    }

    unsubscribe() {
        window.removeEventListener("beforeprint", this.beforePrintCallback);
        window.removeEventListener("afterprint", this.afterPrintCallback)
    }
}