import { ThirdPartyComparisonVehicleCard } from "./thirdPartyComparisonVehicleCard";
import Skeleton from "react-loading-skeleton";

export const ThirdPartyComparisonFilter = (props) => {

    return (
        <div className="filter-container">
            <div className="third-party-comparison-filter" id="third-party-comparison-filter-print-only">
                {props?.vehicleInformation?.slice(0, props?.numComparisons)?.map((item, index) => {
                    if (index < 1) {
                        props?.setVehicleOneTrim(item.trimName);
                    }
                    
                    return props?.firstLoadComplete ? 
                        <ThirdPartyComparisonVehicleCard
                            makeOptions={props?.makeList?.map(item => item?.makeName)}
                            selectedMake={props?.vehicleInformation[index]?.make}
                            modelOptions={props?.vehicleInformation[index]?.modelOptions}
                            selectedModel={props?.vehicleInformation[index]?.modelName}
                            year={props?.vehicleInformation[index]?.year}
                            trimOptions={props?.vehicleInformation[index]?.trimOptions}
                            selectedTrimName={props?.vehicleInformation[index]?.trimName}
                            selectedTrimId={props?.vehicleInformation[index]?.trimId}
                            imageUrl={props?.vehicleInformation[index]?.imageUrl}
                            shadowImage={props?.shadowImage}
                            updateMake={props?.updateMake}
                            updateModel={props?.updateModel}
                            updateTrim={props?.updateTrim}
                            vehicleNum={index}
                            loadingTrims={props?.loadingTrims[index]}
                            analyticsData={props?.analyticsData}
                        />
                    :
                        <div className="skeleton-placeholder">
                            <Skeleton height={'100%'} containerClassName="flex-1"/>
                        </div>
                })}
            </div>
            <div className="jato-disclaimer">
                Comparison data provided by JATO. JATO does not warrant the accuracy or completeness of the data. Please use care in your use of the information provided.
            </div>
        </div>
        
    );
};