import React from "react";
import Skeleton from "react-loading-skeleton";
import AppSettings from "../../../appsettings";
import { useEffect, useState, useRef, useContext } from "react";
import '../../../styles/scss/resources-v2/trim-walk.scss';
import DropdownV2 from "../../../components/molecules/dropdownV2";
import formatMSRP from "../../utils/formatMSRP";
import { getS3PathName, getDynamoName } from "../../utils/vehicleName";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import { yearSelect, engineSelect, trimSelect, disclaimerClick } from "./trimWalk.analytics.js";
import { isMobileView, isTabletView } from '../../utils/getScreenSize.js';
import { VehiclePageIsPrint } from "../../../components/contexts/vehiclePagePrintContext.js";
import { capitalizeTitle } from '../../../screens/utils/capitalize.js';

// NEW IMPORTS
import { getFeaturesAndSpecs } from "../../../services/featuresAndSpecsService";
import { getTrims } from "../../../services/trimsService";

export const VehicleChoice = (props) => {
  const disclaimerComponentRef = useRef();
  const [loadingModelTrims, setLoadingModelTrims] = useState(false);
  const [selectedTrimImage, setSelectedTrimImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [cardTitle, setCardTitle] = useState('');
  const [cardSubtitle, setCardSubtitle] = useState('');
  const [footerTitle, setfooterTitle] = useState('');
  const [footerSubtitle, setFooterSubtitle] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [device, setDevice] = useState();

  const isExpandedBeforePrint = useRef();
  const { isPrint } = useContext(VehiclePageIsPrint);

  useEffect(async() => {
    // Load trims and years when the component mounts
    if (props.selectedYear && props.modelName) {
      const s3PathName = await getS3PathName(getDynamoName(props.modelName));
      setImagePath(`${AppSettings.AWSImgRepo.vehicle}${props.selectedYear}/${s3PathName}/en/`); 

      setLoadingModelTrims(true);
      await getTrims(getDynamoName(props.modelName), props.selectedYear, 'en')
          .then((data) => {
              const engines = [...new Set(data?.trims?.map(i => i.engine))]
              props.setEngines(engines);
              props.setTrims(data?.trims)
          })
          .catch(error => console.log(error))
          .finally(() => setLoadingModelTrims(false))
      await getFeaturesAndSpecs(getDynamoName(props.modelName), props.selectedYear, 'en')
          .then((data) => {
            props.updateFeaturesSpecs(data)
          })
          .catch(error => console.log(error))
    }
  }, [props.modelName, props.selectedYear]);

  useEffect(() => {
    if (imagePath && props.selectedTrim?.trimImageFilename) {
      setSelectedTrimImage(encodeURI(`${imagePath}${props.selectedTrim?.trimImageFilename}`))
    }
  }, [props.selectedTrim, imagePath])

  useEffect(() => {
    updateDevice();
    setVehicleVars();
    window.addEventListener("resize", updateDevice);

    return () => {
      window.removeEventListener("resize", updateDevice);
    }
  }, []);

  useEffect(() => {
    if (isPrint) {
      onPrintAdjustments();
    }
    else {
      afterPrintAdjustments();
    }
  },[isPrint])

  const setVehicleVars = () => {
    const comparisonSubtitleText = props.index == 1 ? 'higher' : props.index === 2 ? 'highest' : 'lowest';
    const vehicleNumber = props.index + 1;
    setCardTitle(`VEHICLE ${props.index + 1}`);
    setCardSubtitle(`Select the ${comparisonSubtitleText} grade here.`);
    setfooterTitle(`Vehicle ${vehicleNumber} Features`);
    setFooterSubtitle(`(Adds to Vehicle 1${vehicleNumber == 3 ? ' & 2' : ''})`);
  }

  const updateDevice = () => {
    if (isTabletView(window.innerWidth)) {
      setDevice('tablet');
    }
    else if (isMobileView(window.innerWidth)) {
      setDevice('mobile');
    }
    else {
      setDevice('desktop');
      setIsExpanded(true);
    }
  }

  const onPrintAdjustments = () => {
    setDevice('tablet');
    setIsExpanded((prevValue)=> {isExpandedBeforePrint.current = prevValue; return false;});
  };

  const afterPrintAdjustments = () => {
    updateDevice();
    isExpandedBeforePrint.current != undefined && setIsExpanded(isExpandedBeforePrint.current);
  }

  const handleOnDisclaimerClick = () => {
    disclaimerClick({
      index: props.index,
      modelName: props.modelName,
      modelYear: props.selectedYear,
    })
  }

  const handleYearChange = (selectedYear) => {
    props?.setSelectedYear(selectedYear)

    yearSelect({
      index: props.index,
      page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
      category: `Vehicle ${props.index + 1}`,
      contentTitle: selectedYear,
      modelName: props.modelName,
      modelYear: props.modelYear,
      engine: props.selectedEngine,
      trimName: props.selectedTrim?.fullName
    });
  };

  const handleTrimChange = (modelCode) => {
    updateSelectedTrim(modelCode, props.trims, props.setSelectedTrim)

    trimSelect({
      index: props.index,
      page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
      category: `Vehicle ${props.index + 1}`,
      contentTitle: modelCode,
      modelName: props.modelName,
      modelYear: props.modelYear,
      trimName: props.selectedTrim?.fullName
    });
  };

  const handleEngineChange = (engine) => {
    props.setSelectedEngine(engine)

    engineSelect({
      index: props.index,
      page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
      category: `Vehicle ${props.index + 1}`,
      contentTitle: engine,
      modelName: props.modelName,
      modelYear: props.modelYear,
      trimName: props.selectedTrim?.fullName
    });
  };

  const updateSelectedTrim = (modelCode, trims, setTrim) => {
    // find full trim data from model code selection and set state
    const foundTrim = trims.find(t => t.modelCode === modelCode)
    setTrim(foundTrim)
  }

  const buildTrimDropdownOptions = (trims) => {
    return trims?.map(t => {
      return {
        value: t.modelCode,
        label: (
          <span>
            <span className="engine-label">{t.fullName}</span>
            <span className="model-code-label">{t.modelCode}</span>
          </span>
        ),
      };
    })
  }

  const toggleCardExpand = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="trim-selection-v2">
      <div className="vehicle-title-text">
        {cardTitle}
      </div>
      <div className="vehicle-subtitle-text">
        {cardSubtitle}
      </div>
      {(props.selectedYear && props.selectedEngine && props.selectedTrim) ? <div className={`trim-walk-selection-wrapper${' vehicle' + props.index}`}>
        <div className={`trim-walk-selection-container${ device == 'mobile' && !isExpanded ? ' trim-walk-selection-container-m-c' : ''}`}>
          <div className={`trim-walk-selection-content${ device == 'mobile' && !isExpanded ? ' trim-walk-selection-content-m-c' : ''}`}>
          {(device == 'tablet' || (device == 'mobile' && !isExpanded)) && <button className="trim-walk-show-more-less" onClick={toggleCardExpand}>{`Show ${isExpanded ? 'Less' : 'More'}`}</button>}
            {isExpanded && <div className="trim-walk-dropdowns">
              <div className="trim-walk-dropdown-year">
                <DropdownV2
                  options={props.years || []}
                  onValueChange={y => handleYearChange(y.value)}
                  placeholder="Year"
                  value={props.selectedYear}
                  style={"white"}
                />
              </div>
              <div className="trim-walk-dropdown-engine">
                <DropdownV2
                  options={props.engines || []}
                  style={"white"}
                  placeholder={loadingModelTrims ? 'Loading Engines...' : 'Engine'}
                  disabled={loadingModelTrims}
                  value={props.selectedEngine}
                  onValueChange={e => handleEngineChange(e.value)}
                />
              </div>
              <div className="trim-walk-dropdown-trim">
                <DropdownV2
                  options={buildTrimDropdownOptions(props.trims) || []}
                  style={"white"}
                  placeholder={loadingModelTrims ? 'Loading Trims...' : 'Trim'}
                  disabled={loadingModelTrims}
                  value={props.selectedTrim?.fullName}
                  onValueChange={t => {handleTrimChange(t.value); device != 'desktop' && toggleCardExpand()}}
                />
              </div>
            </div>}
            {(!isExpanded && device == 'tablet') &&
              <div className="trim-walk-trim-name">{`${isPrint ? props.selectedYear + ' ' + capitalizeTitle(props.modelName) + ' ' + props.selectedEngine + ' ' : ''}${props.selectedTrim?.fullName}`}</div>
            }
            <div className="trim-walk-selection-bottom">
              <div className={`model-msrp${ device == 'mobile' && !isExpanded ? ' model-msrp-m-c' : ''}`}>
                <div className="model-number">{props.selectedTrim.modelCode}</div>
                <div className="msrp">
                  <span className={`base-msrp${ device == 'mobile' && !isExpanded ? ' base-msrp-m-c' : ''}`}>Base MSRP: <span className={`${device == 'mobile' && !isExpanded ? ' base-msrp-value-m-c' : ''}`}>{formatMSRP(props.selectedTrim?.msrp)}</span><sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup></span>
                  {props.index >= 1 && !(device == 'mobile' && !isExpanded) && <span className="additional-msrp">(Add'l MSRP: {formatMSRP(props.selectedTrim?.msrp - props.minMsrp)})</span>}
                </div>
              </div>
            </div>
            {props.index >= 1 && (device != 'mobile' || (device == 'mobile' && isExpanded)) && 
            <div className="trim-walk-selection-footer">
              <div className="footer-title">{footerTitle}</div>
              <div className="footer-subtitle">{footerSubtitle}</div>
            </div>}
          </div>
          <div className={`trim-walk-selection-image${ device == 'mobile' ? (isExpanded ? ' trim-walk-selection-image-m-e' : ' trim-walk-selection-image-m-c'): ''}`}>
            <div className={`image-container${ device == 'mobile' && isExpanded ? ' image-container-m-e' : ''}`}>
              <img  
                src={selectedTrimImage} 
                alt={props.selectedTrim?.name}
                onError={() =>
                  setSelectedTrimImage(
                    encodeURI(
                      AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                    ).toString()
                  )
                }
              />
              {(!isExpanded && device == 'mobile') &&
              <div className="trim-walk-trim-name">{props.selectedTrim?.fullName}</div>
            }{props.index >= 1 && device == 'mobile' && !isExpanded &&
                <div className="trim-walk-selection-footer">
                <div className="footer-title">{footerTitle}</div>
                <div className="footer-subtitle">{footerSubtitle}</div>
                </div>}
            </div>
          </div>
          {device == 'mobile' && isExpanded && <button className="trim-walk-show-more-less" onClick={toggleCardExpand}>{`Show ${isExpanded ? 'Less' : 'More'}`}</button>}
        </div>
        <DisclaimersComponent
          template={`${props.selectedYear}_${props.modelName}`}
          ref={disclaimerComponentRef}
          addBaseMSRPDisclaimer={true}
          analyticsOnSelection={handleOnDisclaimerClick}
        />
      </div> : <Skeleton height={249} /> }
    </div>
  );
};