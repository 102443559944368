import "../../../styles/css/vp-header/vp-header.css";

const Header = ({ title, subtitle }) => {
  return (
    <div className="header-container">
      <span className="header-title">{title}</span>
      {subtitle && <span className="header-subtitle" id="header-subtitle-print-only">{subtitle}</span>}
    </div>
  );
};

export default Header;
